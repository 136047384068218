* {
  image-rendering: pixelated;
}

#host {
  background: url("host_background.e93f4ea2.png");
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 222px;
  height: 25px;
  display: flex;
  position: absolute;
  top: 650px;
}

#host .host-name {
  text-transform: lowercase;
  color: #e2881d;
  text-shadow: 0 0 8px #e2881d00;
  font-family: edit-undo;
  font-size: 19px;
}

#break-host {
  background: url("break-host-bg.00b1f0d6.png");
  justify-content: flex-end;
  align-items: center;
  width: 210px;
  height: 34px;
  display: flex;
  position: absolute;
  top: 740px;
}

#break-host .break-host-name {
  color: #ed3636;
  text-align: center;
  text-transform: uppercase;
  width: 184px;
  height: 17px;
  font-family: edit-undo;
  font-size: 21px;
  overflow: hidden;
}

#break-host .line {
  width: 180px;
  height: 1px;
  position: absolute;
  right: 2px;
}

#break-host .line.line-1 {
  background-color: #091e31;
  top: 14px;
}

#break-host .line.line-2 {
  background-color: #0a1d30;
  top: 17px;
}

#break-host .line.line-3 {
  background-color: #0b1c2e;
  top: 20.5px;
}
/*# sourceMappingURL=index.7b6c6290.css.map */
